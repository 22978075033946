import React from 'react'
import './App.css'
import IdentifierDialog from './components/IdentifierDialog'
import { theme, ThemeProvider } from '@papertrail/styleguide'
import { BrowserRouter } from 'react-router-dom'

export default function Root() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <IdentifierDialog />
      </BrowserRouter>
    </ThemeProvider>
  )
}
