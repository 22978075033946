import React from 'react'
import { IdentifierRowData } from '../types'
import { OutlinedInput, Box, Stack } from '@papertrail/styleguide'

type Props = {
  identifier: IdentifierRowData
  fieldChanged: (recordId: string, field: string, value: string) => void
  pasteMultiple: (text: string, recordId: string, fieldKey: string) => void
}

export default function IdentifierRow(props: Props) {
  const { identifier, fieldChanged, pasteMultiple } = props

  function onChange(recordId, field, value) {
    fieldChanged(recordId, field, value)
  }

  function paste(e, recordId, fieldKey) {
    e.preventDefault()
    const text = e.clipboardData.getData('Text')
    pasteMultiple(text, recordId, fieldKey)
  }

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        {identifier.recordImageUrl && <img style={{ width: '32px', height: '32px' }} src={identifier.recordImageUrl} />}

        {!identifier.recordImageUrl && (
          <svg ng-switch-default="" viewBox="0 0 24 28" style={{ width: '32px', height: '32px' }}>
            <title>Record</title>
            <path
              fill="#DFE6EC"
              d="M22.937 5.938c0.578 0.578 1.062 1.734 1.062 2.562v18c0 0.828-0.672 1.5-1.5 1.5h-21c-0.828 0-1.5-0.672-1.5-1.5v-25c0-0.828 0.672-1.5 1.5-1.5h14c0.828 0 1.984 0.484 2.562 1.062zM16 2.125v5.875h5.875c-0.094-0.266-0.234-0.531-0.344-0.641l-4.891-4.891c-0.109-0.109-0.375-0.25-0.641-0.344zM22 26v-16h-6.5c-0.828 0-1.5-0.672-1.5-1.5v-6.5h-12v24h20zM6 12.5c0-0.281 0.219-0.5 0.5-0.5h11c0.281 0 0.5 0.219 0.5 0.5v1c0 0.281-0.219 0.5-0.5 0.5h-11c-0.281 0-0.5-0.219-0.5-0.5v-1zM17.5 16c0.281 0 0.5 0.219 0.5 0.5v1c0 0.281-0.219 0.5-0.5 0.5h-11c-0.281 0-0.5-0.219-0.5-0.5v-1c0-0.281 0.219-0.5 0.5-0.5h11zM17.5 20c0.281 0 0.5 0.219 0.5 0.5v1c0 0.281-0.219 0.5-0.5 0.5h-11c-0.281 0-0.5-0.219-0.5-0.5v-1c0-0.281 0.219-0.5 0.5-0.5h11z"></path>
          </svg>
        )}
        {identifier.recordName}
      </Box>

      {identifier.fields.map((field) => (
        <Stack
          sx={{ height: '100%', alignItems: 'center', justifyContent: 'center', direction: 'row' }}
          key={`${identifier.recordId}-${field.key}`}>
          <OutlinedInput
            value={field.value}
            onChange={(e) => onChange(identifier.recordId, field.key, e.target.value)}
            onPaste={(e) => paste(e, identifier.recordId, field.key)}
            label=""
            fullWidth
            placeholder={`Enter ${field.label.toLowerCase()}...`}
          />
        </Stack>
      ))}
    </>
  )
}
