import { useApiGetMany, useApiPatch, useApiGet } from '@papertrail/web3-utils'
import { Record, IdentifierRowData } from './types'

export function useApiGetAccount(id: string) {
  const [accountState, getAccount] = useApiGet(`/accounts/${id}`, (data) => data)
  return [accountState, getAccount] as const
}

export function useApiGetRecords(accountId: string, recordIds: string) {
  const [state, loadFirstPage] = useApiGetMany<Record>(
    `/accounts/${accountId}/records?record_id=${recordIds}&include=uploads,fields.state`,
    1000,
    (data) => data
  )

  function load() {
    loadFirstPage({})
  }

  return [state, load] as const
}

export function useSaveIdentifierBatch(accountId: string) {
  const [state, post] = useApiPatch(`/accounts/${accountId}/records/batch/fields`, (data) => data)

  function mapFields(identifierFields, blueprintFields) {
    const result = {}
    const allKeys = blueprintFields.map((f) => f.key)
    for (const key of allKeys) {
      const field = identifierFields.find((f) => f.key === key)
      const val = field ? field.value : null
      result[key] = identifierFields[key] = val
    }

    return result
  }

  function postFieldValueChanges(identifiers: IdentifierRowData[], blueprintFields) {
    const payload = identifiers.map((identifier: IdentifierRowData) => {
      const cleanedFields = mapFields(identifier.fields, blueprintFields)
      return {
        record_id: identifier.recordId,
        fields: cleanedFields
      }
    })

    post({ data: payload })
  }

  return [state, postFieldValueChanges] as const
}
